import "./App.css";
import "./styles.scss";
import 'bootstrap-icons/font/bootstrap-icons.css';
import Routes from "./routes";
function App() {
  return <Routes />;
}




export default App;
